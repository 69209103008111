<template>
  <div class="card shadow">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col-6">
          <h3 class="mb-0">
            {{ tableTitle }}
          </h3>
        </div>
        <div class="col-6 text-right">
          <slot name="headerButtons"> </slot>
        </div>
      </div>
    </div>

    <slot name="formDrawer"></slot>

    <div class="table-responsive mb-0">
      <a-spin :spinning="loading">
        <vuetable
          ref="vuetable"
          :fields="fields"
          :sort-order="sortOrder"
          no-data-template="Empty"
          :css="css.table"
          :show-sort-icons="true"
          :per-page="perPage"
          pagination-path=""
          data-path="data"
          :api-url="apiUrl"
          :append-params="appendedParams"
          :http-options="httpOptions"
          @vuetable:checkbox-toggled-all="toggleCheckbox"
          @vuetable:checkbox-toggled="toggleCheckbox"
          @vuetable:pagination-data="onPaginationData"
          @vuetable:loading="loading = true"
          @vuetable:load-success="loading = false"
          @vuetable:load-error="onLoadError"
        >
          <template slot="custom-slot" slot-scope="props">
            <center>
              <div class="table-button-container">
                <slot name="customComponent" :tableProps="props"></slot>
              </div>
            </center>
          </template>

          <template slot="actions" slot-scope="props">
            <center>
              <div class="table-button-container">
                <slot name="tableButtons" :tableProps="props"></slot>
              </div>
            </center>
          </template>
        </vuetable>
      </a-spin>
    </div>

    <div class="card-footer d-flex justify-content-end">
      <div class="pagination ui basic segment grid">
        <vuetable-pagination
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
          :css="css.pagination"
        >
        </vuetable-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable';
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination';
import secureStorage from '../../controllers/secureStorage';

export default {
  name: 'PageTable',
  props: {
    tableTitle: String,
    tableFields: Array,
    apiUrl: String,
    perPage: {
      type: Number,
      default: 10
    },
    appendedParams: {
      type: Object,
      default: undefined
    },
    sortOrder: {
      type: Array,
      default: function() {
        return [
          {
            field: 'id',
            direction: 'asc'
          }
        ];
      }
    }
  },
  components: {
    Vuetable,
    VuetablePagination
  },
  data() {
    return {
      checkedItems: [],
      loading: false,
      fields: this.tableFields,

      httpOptions: {
        headers: {
          Authorization:
            'Bearer ' + secureStorage.getItem('clpSitePageAccessToken')
        }
      },
      css: {
        table: {
          tableClass:
            'table table-sm table-striped table-bordered table-hovered',
          loadingClass: 'loading'
        },
        pagination: {
          infoClass: 'pull-right',
          wrapperClass: 'vuetable-pagination pull-right',
          activeClass: 'btn-primary btn-sm',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    };
  },
  methods: {
    toggleCheckbox() {
      this.checkedItems = this.$refs.vuetable.selectedTo;
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    async onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onLoadError() {
      this.$message.error('Unable to fetch data.', 10);
      this.loading = false;
    },
    refreshTable() {
      this.$refs.vuetable.refresh();
      console.log('refreshTable');
    }
  },
  mounted() {
    this.$root.$on('refreshTable', () => {
      return this.refreshTable();
    });
    console.log('Table mounted.');
  }
};
</script>
<style scoped></style>

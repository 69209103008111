<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8" :style="sitePageTheme.header">
      <router-link
        :to="{
          name: 'Rewards',
          params: { siteName: this.clpSitePageSettings.siteName }
        }"
        tag="button"
        class="btn btn-link text-uppercase"
        style="color:#484c54"
      >
        &#8592; Back to Rewards</router-link
      >
    </base-header>
    <div class="container-fluid mt--7" :style="sitePageTheme.body">
      <div class="row">
        <div class="col">
          <div class="card mb-3">
            <div class="row no-gutters">
              <div class="col">
                <div class="card-body">
                  <PageTable
                    :tableTitle="table.title"
                    :apiUrl="table.apiUrl"
                    :tableFields="table.fields"
                    :appendedParams="table.filters"
                    :sortOrder="table.sortOrder"
                  >
                    <template #customComponent="{tableProps}">
                      <div>
                        {{ tableProps.rowData.running_balance }}
                        (<small
                          v-if="tableProps.rowData.points >= 0"
                          class="text-success"
                          >{{ tableProps.rowData.points }}</small
                        >
                        <small
                          v-else-if="tableProps.rowData.points < 0"
                          class="text-danger"
                          >{{ tableProps.rowData.points }}</small
                        >)
                      </div>
                    </template>
                  </PageTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTable from '../../components/Page/Table.vue';
import moment from 'moment-timezone';

const TIMEZONE = process.env.VUE_APP_TIMEZONE;

export default {
  name: 'PointsHistory',
  components: {
    PageTable
  },
  props: {
    clpSitePageSettings: Object,
    memberData: Object,
    sitePageTheme: Object
  },
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      table: {
        title: 'Points History',
        apiUrl: '/api/v2/clp_members/points_history',
        fields: [
          {
            name: 'reference_transaction_date',
            title: 'Transaction Date',
            formatter(date) {
              return moment(date)
                .tz(TIMEZONE)
                .format('MM/DD/YYYY hh:mm:ss a');
            }
          },
          { name: 'site.name', title: 'Site' },
          { name: 'transactionType.name', title: 'Transaction Type' },
          { name: 'custom-slot', title: 'Running Balance' }
        ],
        filters: {
          member_id: null,
          transaction_type_id: null,
          site_id: null,
          transaction_id: null,
          transaction_date_from: null,
          transaction_date_to: null
        },
        sortOrder: [
          {
            field: 'id',
            direction: 'desc'
          }
        ]
      },
      transactionTypes: [],
      sites: [],
      runningBalance: 0
    };
  },
  methods: {
    moment,
    applyFilter() {
      this.$root.$emit('refreshTable');
    },

    dateRangeOnChange(dateObj, dateString) {
      this.table.filters.transaction_date_from = dateString[0];
      this.table.filters.transaction_date_to = dateString[1];
      console.log(dateObj);
    }
  },

  created() {
    this.table.filters.member_id = this.memberData.id;

    if (!this.clpSitePageSettings.allow_trx_history_view) {
      this.$router.push(`/${this.$route.params.siteName}/rewards`);
    }
  },

  watch: {
    'table.filters.transaction_date_from': function(val) {
      if (val == '') {
        this.table.filters.transaction_date_from = null;
      }
    },

    'table.filters.transaction_date_to': function(val) {
      if (val == '') {
        this.table.filters.transaction_date_to = null;
      }
    }
  }
};
</script>

<style scoped></style>
